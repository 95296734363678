import React from "react"
import { graphql, Link } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import singleblogStyles from "./singleblog.module.scss"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "MMM D, YYYY")
      body {
        raw
        references {
          ... on ContentfulAsset {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`

const Blog = props => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const alt = props.data.contentfulBlogPost.body.references[0].title
        const url = props.data.contentfulBlogPost.body.references[0].file.url

        return <img alt={alt} src={url} />
      },
    },
  }

  return (
    <Layout>
      <Head title={props.data.contentfulBlogPost.title} />
      <ul className={singleblogStyles.breadcrumb}>
        <li className={singleblogStyles.breadcrumbItem}>
          <Link to="/blog">Blog</Link>
        </li>
        <li
          className={`${singleblogStyles.breadcrumbItem} ${singleblogStyles.breadcrumbItemActive}`}
        >
          {props.data.contentfulBlogPost.title}
        </li>
      </ul>
      <div className={singleblogStyles.content}>
        <h1 className="mb-2">{props.data.contentfulBlogPost.title}</h1>
        <p className="small text-muted">
          Posted on {props.data.contentfulBlogPost.publishedDate}
        </p>
        {documentToReactComponents(
          JSON.parse(props.data.contentfulBlogPost.body.raw),
          options
        )}
      </div>
    </Layout>
  )
}

export default Blog
